'use client'

import { useSignals } from '@preact/signals-react/runtime'
import ExternalLink from '@susu/headless-commerce/components/ExternalLink/ExternalLink'
import type { Locale } from '@susu/headless-commerce/config/locale'
import type {
  HeroLinkItem,
  NavigationLink,
} from '@susu/headless-commerce/gql/generated/graphql'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import type { CountryConfiguration } from '@susu/headless-commerce/types/CountryConfiguration'
import { generateUrlFromLinkContent } from '@susu/headless-commerce/utils/UrlGenerator'
import { generateIdentifier } from '@susu/headless-commerce/utils/identifier'
import type { PromotionEvents } from '@susu/headless-commerce/utils/tracking/tracking'
import {
  trackClickPromotion,
  trackImpressionPromotion,
} from '@susu/headless-commerce/utils/tracking/tracking'
import classNames from 'classnames'
import { memo, useCallback, useEffect, useRef } from 'react'

import styles from './HeroBannerLink.module.scss'

export type HeroBannerLinkProps = {
  item: HeroLinkItem
  country: CountryConfiguration
  locale: Locale
}

export const HeroBannerLink = memo(function HeroBannerLink({
  item,
  country,
  locale,
}: HeroBannerLinkProps) {
  useSignals()
  const pageType = usePageType()
  const heroLinkRef = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (heroLinkRef?.current) {
      trackImpressionPromotion(
        heroLinkRef,
        item?.link?.promotionEvents as PromotionEvents,
        pageType,
        {
          locale,
          country,
        },
      )
    }
  }, [country, item?.link?.promotionEvents, locale, pageType])

  const onHeroLinkClick = useCallback(() => {
    if (!item?.link?.promotionEvents) {
      return
    }

    trackClickPromotion(heroLinkRef, item?.link?.promotionEvents, pageType, {
      locale,
      country,
    })
  }, [country, item?.link?.promotionEvents, locale, pageType])

  return (
    <li
      className={classNames('title-01-medium', styles['hero-banner-link'])}
      key={item?.sys?.id || generateIdentifier()}
      ref={heroLinkRef}
    >
      <ExternalLink
        className={styles['hero-banner-link__item']}
        href={generateUrlFromLinkContent(
          item?.link as NavigationLink,
          country.siteID,
          locale,
        )}
        onClick={onHeroLinkClick}
        automationId={`quicklinks_${String(item?.link?.automationId)}`}
      >
        {item?.linkText}
      </ExternalLink>
    </li>
  )
})
