'use client'

import { useSignals } from '@preact/signals-react/runtime'
import ExternalLink from '@susu/headless-commerce/components/ExternalLink/ExternalLink'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { customer } from '@susu/headless-commerce/contexts/customer'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import type {
  Cta,
  NavigationLink,
  PromotionEvents,
} from '@susu/headless-commerce/gql/generated/graphql'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import { generateUrlFromLinkContent } from '@susu/headless-commerce/utils/UrlGenerator'
import { parseAttributeFromString } from '@susu/headless-commerce/utils/attributeParser'
import {
  trackClickPromotion,
  trackImpressionPromotion,
} from '@susu/headless-commerce/utils/tracking/tracking'
import { memo, useCallback, useEffect, useRef } from 'react'

import styles from './StoryboardLink.module.scss'

export type ClientStoryboardLinkProps = {
  cta: Cta
}

export const ClientStoryboardLink = memo(function ClientStoryboardLink({
  cta,
}: Readonly<ClientStoryboardLinkProps>) {
  useSignals()
  const country = useCountry()
  const locale = useLocale()
  const pageType = usePageType()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref?.current && customer.value) {
      trackImpressionPromotion(
        ref,
        cta?.link?.promotionEvents as PromotionEvents,
        pageType,
        {
          locale,
          country,
        },
      )
    }
  }, [country, cta?.link?.promotionEvents, locale, pageType])

  const onClick = useCallback(() => {
    if (!cta.link?.promotionEvents) {
      return
    }

    if (customer.value) {
      trackClickPromotion(
        ref,
        cta.link?.promotionEvents as PromotionEvents,
        pageType,
        {
          locale,
          country,
        },
      )
    }
  }, [country, cta.link?.promotionEvents, locale, pageType])

  const url = generateUrlFromLinkContent(
    cta?.link as NavigationLink,
    country.siteID,
    locale,
  )

  return (
    <div
      ref={ref}
      className={styles['link-wrap']}
      {...parseAttributeFromString(cta.testAutomationAttribute)}
    >
      <ExternalLink
        href={url}
        onClick={onClick}
        className={`${styles.link} title-01_5-medium`}
        automationId={`storyboard_${String(cta?.link?.automationId)}`}
      >
        {cta?.title}
      </ExternalLink>
    </div>
  )
})
